import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromPersons from '../reducers/persons.reducer';
import { LoadingState } from 'src/app/_shared/configs.model';

export const selectState = createFeatureSelector<fromPersons.State>(fromPersons.featureKey);

export const selectAll = createSelector(selectState, fromPersons.selectAll);

export const selectEntities = createSelector(selectState, fromPersons.selectEntities);

export const selectIsLoaded = createSelector(
  selectState,
  (state) => state.callState === LoadingState.LOADED
);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.callState === LoadingState.LOADING
);

export const selectTotalElementsCount = createSelector(
  selectState,
  fromPersons.getTotalElementsCount
);

export const selectPageIndex = createSelector(selectState, fromPersons.getPageIndex);

export const selectIsUnique = createSelector(selectState, fromPersons.getIsUnique);

export const selectById = ({ uuid }) =>
  createSelector(selectEntities, (entities) => entities[uuid]);
