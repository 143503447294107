import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as PersonActions from '../actions/person.actions';
import { Configs, LoadingState } from 'src/app/_shared/configs.model';
import { Person } from '../models/person.model';

export const featureKey = 'persons';

export interface State extends EntityState<Person.Model> {
  // additional entities state properties
  callState: Configs.CallState;
  pageIndex: number | null;
  pageSize: number | null;
  totalPages: number | null;
  totalElementsCount: number | null;
  isUnique: boolean | null;
}

export const adapter: EntityAdapter<Person.Model> = createEntityAdapter<Person.Model>({
  selectId: (person) => person.uuid,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  callState: LoadingState.INIT,
  pageIndex: null,
  pageSize: 10,
  totalPages: null,
  totalElementsCount: null,
  isUnique: null,
});

export const reducer = createReducer(
  initialState,
  on(PersonActions.setPageSize, (state, { pageSize }) => ({
    ...state,
    pageSize,
  })),
  on(PersonActions.addSuccess, (state, { payload }) => {
    if (state.ids.length < state.pageSize) {
      return adapter.addOne(payload, {
        ...state,
        totalElementsCount: state.totalElementsCount + 1,
      });
    }
    return { ...state, totalElementsCount: state.totalElementsCount + 1 };
  }),
  on(
    PersonActions.updateSuccess,
    PersonActions.activateSuccess,
    PersonActions.deactivateSuccess,
    (state, { payload }) => adapter.updateOne(payload, state)
  ),
  on(PersonActions.loadByIdSuccess, (state, { payload }) => adapter.upsertOne(payload, state)),
  on(PersonActions.load, (state) => ({
    ...state,
    callState: LoadingState.LOADING,
  })),
  on(
    PersonActions.loadSuccess,
    (state, { payload: { data, pageIndex, totalElementsCount, totalPages } }) =>
      adapter.setAll(data, {
        ...state,
        pageIndex,
        totalPages,
        totalElementsCount,
        callState: LoadingState.LOADED,
      })
  ),
  on(PersonActions.deleteItemSuccess, (state, { uuid }) =>
    adapter.removeOne(uuid, {
      ...state,
      totalElementsCount: state.totalElementsCount - 1,
    })
  )
  // on(PersonActions.checkExistanceSuccess, (state, { isUnique }) => ({
  //   ...state,
  //   isUnique,
  // }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getTotalElementsCount = (state: State) => state.totalElementsCount;
export const getPageIndex = (state: State) => state.pageIndex;
export const getIsUnique = (state: State) => state.isUnique;
