import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Person } from '../models/person.model';
import { Configs } from 'src/app/_shared/configs.model';

export const setPageSize = createAction(
  '[Person/API] Set Page Size',
  props<{ pageSize: number }>()
);

export const closeDialog = createAction('[Person/API] Close Person Dialog');

export const cancelDialog = createAction('[Person/API] Cancel Person Dialog');

export const changeStatusConfirmDialog = createAction(
  '[Person/API] Change Status Confirm Dialog',
  props<{ uuid: string; active: boolean }>()
);

export const deleteDialog = createAction(
  '[Person/API] Delete Person Dialog',
  props<{ uuid: string }>()
);

export const addDialog = createAction(
  '[Person/API] Add Person Dialog',
  props<{ initData?: any }>()
);

export const editDialog = createAction(
  '[Person/API] Edit Person Dialog',
  props<{ uuid: string }>()
);

export const loadById = createAction('[Person/API] Load Person By Id', props<{ uuid: string }>());

export const loadByIdSuccess = createAction(
  '[Person/API] Load Person By Id Success',
  props<{ payload: Person.Model }>()
);

export const loadByIdFailure = createAction(
  '[Person/API] Load Person By Id Failure',
  props<{ errorMsg: any }>()
);

export const loadByIdForDialog = createAction(
  '[Person/API] Load Person By Id For Dialog',
  props<{ uuid: string }>()
);

export const loadByIdForDialogSuccess = createAction(
  '[Person/API] Load Person By Id For Dialog Success',
  props<{ payload: Person.Model }>()
);

export const loadByIdForDialogFailure = createAction(
  '[Person/API] Load Person By Id For Dialog Failure',
  props<{ errorMsg: any }>()
);

export const load = createAction('[Persons/API] Load Persons', props<Configs.GetQueryParams>());

export const loadSuccess = createAction(
  '[Persons/API] Load Persons Success',
  props<{ payload: Configs.PaginationModel<Person.Model> }>()
);

export const loadFailure = createAction(
  '[Persons/API] Load Persons Failure',
  props<{ error: any }>()
);

export const add = createAction('[Persons/API] Add Person', props<{ payload: Person.Model }>());

export const addSuccess = createAction(
  '[Persons/API] Add Person Success',
  props<{ payload: Person.Model }>()
);

export const addFailure = createAction('[Persons/API] Add Person Failure', props<{ error: any }>());

export const update = createAction(
  '[Persons/API] Update Persons',
  props<{ payload: Person.Model }>()
);

export const updateSuccess = createAction(
  '[Persons/API] Update Person Success',
  props<{ payload: Update<Person.Model> }>()
);

export const updateFailure = createAction(
  '[Persons/API] Update Person Failure',
  props<{ error: any }>()
);

export const activate = createAction('[Persons/API] Activate Person', props<{ uuid: string }>());

export const activateSuccess = createAction(
  '[Persons/API] Activate Person Success',
  props<{ payload: Update<Person.Model> }>()
);

export const activateFailure = createAction(
  '[Persons/API] Activate Person Failure',
  props<{ error: any }>()
);

export const deactivate = createAction(
  '[Persons/API] Deactivate Person',
  props<{ uuid: string }>()
);

export const deactivateSuccess = createAction(
  '[Persons/API] Deactivate Person Success',
  props<{ payload: Update<Person.Model> }>()
);

export const deactivateFailure = createAction(
  '[Persons/API] Deactivate Person Failure',
  props<{ error: any }>()
);

export const deleteItem = createAction('[Persons/API] Delete Person', props<{ uuid: string }>());

export const deleteItemSuccess = createAction(
  '[Persons/API] Delete Person Success',
  props<{ uuid: string }>()
);

export const deleteItemFailure = createAction(
  '[Persons/API] Delete Person Failure',
  props<{ error: any }>()
);

// export const checkExistance = createAction(
//   '[Persons/API] Check Existance',
//   props<{ payload: { field: string; value: string; entityUUID: string } }>()
// );

// export const checkExistanceSuccess = createAction(
//   '[Persons/API] Check Existance Success',
//   props<{ isUnique: boolean }>()
// );

// export const checkExistanceFailure = createAction(
//   '[Persons/API] Check Existance Failure',
//   props<{ error: any }>()
// );
